import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentSection } from "../components/ContentSection"
import TopSubNav from "../components/TopSubNav"
import styled from "styled-components"
import CallToActionContact from "../components/CallToActionContact"
import { formatRegularLinks } from "../hooks/useFormattedRegularLinks"
import SideNav from "../components/SideNav"
import TwoColContainer from "../components/TwoColContainer"
import ServiceContentContainer from "../components/ServiceContentContainer"
import ServiceRelatedClientResults from "../components/ServiceRelatedClientResults"

export const query = graphql`
  query ServiceQuery($uid: String) {
    prismic {
      allServices(uid: $uid) {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            top_sub_nav {
              nav_item
            }
            _meta {
              uid
            }
            body {
              ... on PRISMIC_ServiceBodyText {
                type
                label
                primary {
                  content_section_title
                  text
                }
              }
            }
            related_client_results {
              client_result {
                ... on PRISMIC_Client_result {
                  title
                  short_description
                  feature_image
                  _meta {
                    lastPublicationDate
                    uid
                  }
                }
              }
            }
          }
        }
      }
      services(lang: "en-us", uid: "services") {
        left_side_nav {
          nav_item {
            ... on PRISMIC_Service {
              _meta {
                uid
              }
              seo_h1
            }
          }
        }
      }
    }
  }
`

const StyledContentSection = styled(ContentSection)`
  .inner-container {
    max-width: 900px;
  }
`

export default ({ data }) => {
  const doc = data.prismic.allServices.edges.slice(0, 1).pop()
  if (!doc) return null
  console.log(doc.node.top_sub_nav)
  const regularLinks = doc.node.top_sub_nav
    ? formatRegularLinks(doc.node.top_sub_nav)
    : []

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  const links =
    (doc.node &&
      data.prismic.services.left_side_nav &&
      data.prismic.services.left_side_nav.map(item => ({
        key: item.nav_item._meta.uid,
        stub: `/services/${item.nav_item._meta.uid}`,
        displayName: `${RichText.asText(item.nav_item.seo_h1)}`,
      }))) ||
    []

  const clientResults = doc.node.related_client_results

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav
        title={RichText.asText(doc.node.page_title)}
        regularLinks={regularLinks}
      />
      <ContentSection>
        <div className="inner-container">
          <TwoColContainer>
            <div className="left-col">
              <SideNav links={links} />
            </div>
            <div className="right-col">
              {doc.node.seo_h1 && RichText.render(doc.node.seo_h1)}
              <ServiceContentContainer
                content={doc.node.body}
                seoH1={doc.node.seoH1}
              />
              {clientResults && (
                <ServiceRelatedClientResults clientResults={clientResults} />
              )}
            </div>
          </TwoColContainer>
        </div>
      </ContentSection>
      <CallToActionContact />
    </Layout>
  )
}
