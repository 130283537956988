import React from "react"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"
import ClampLines from "react-clamp-lines"
import { Link } from "gatsby"

const Container = styled("div")`
  margin-top: 4rem;
`

const CardContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-gap: 1rem;

  .card-container-link {
    color: black;
    text-decoration: none;
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`

const Card = styled("div")`
  border: solid 1px #e3e3e3;
  border-radius: 3px;
  transform: translateY(0);
  transition: all 0.25s ease;
  padding: 1rem;
  height: 100%;

  p {
    font-size: 0.9rem;
  }

  .feature-image {
    position: relative;
    height: 180px;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: -1rem;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: ${props =>
        props.imageUrl ? `url(${props.imageUrl})` : "none"};
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  &:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  }

  @media screen and (max-width: 720px) {
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
`

const SeviceRelatedClientResults = ({ clientResults }) => {
  return (
    <Container>
      <h3>Client Results</h3>
      <CardContainer>
        {clientResults &&
          clientResults.map(({ client_result: item }) => (
            <Link
              to={`/client-result/${item._meta.uid}`}
              className="card-container-link"
            >
              <Card
                key={item._meta.uid}
                imageUrl={
                  item.feature_image && item.feature_image.url
                    ? item.feature_image.url
                    : "https://images.prismic.io/crgwebsite/a5754dbe-1713-4a19-a49a-20993bf5f6e6_crg-empty-image-logo.svg?auto=compress,format"
                }
              >
                <div className="feature-image" />
                <div className="desktop">
                  {item.title && (
                    <ClampLines
                      text={RichText.asText(item.title)}
                      id={`title-${item._meta.uid}`}
                      lines={2}
                      ellipsis="..."
                      innerElement="h3"
                      buttons={false}
                    />
                  )}
                  {item.short_description && (
                    <ClampLines
                      text={RichText.asText(item.short_description)}
                      id={`description-${item._meta.uid}`}
                      lines={4}
                      ellipsis="..."
                      innerElement="p"
                      buttons={false}
                    />
                  )}
                </div>
                <div className="mobile">
                  {item.title && <h3>{RichText.asText(item.title)}</h3>}
                  {item.short_description && (
                    <p>{RichText.asText(item.short_description)}</p>
                  )}
                </div>
              </Card>
            </Link>
          ))}
      </CardContainer>
    </Container>
  )
}

export default SeviceRelatedClientResults
