import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"

const ServiceContentContainer = ({ content }) => {
  return (
    <>
      {content.map((item, i) => (
        <div>
          {item.primary.content_section_title && (
            <h4>{RichText.asText(item.primary.content_section_title)}</h4>
          )}
          <div className="section-text">
            {RichText.render(item.primary.text, linkResolver)}
          </div>
        </div>
      ))}
    </>
  )
}

export default ServiceContentContainer
